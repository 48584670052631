const config = {
  HTTP_REQUEST_URL: import.meta.env.VITE_SERVER_BASEURL,

  OSS_URL: import.meta.env.VITE_OSS_URL,

  H5_SITE_URL: import.meta.env.VITE_H5_SITE_URL,

  // 以下配置在不做二开的前提下,不需要做任何的修改
  HEADER: {
    'content-type': 'application/json',
    'cb-lang': 'zh-CN',
    // #ifdef WEB
    // 'Form-type': navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 ? 'wechat' : 'h5',
    'Form-type': 'wechat',
    // #endif
    // #ifdef MP
    'Form-type': 'routine',
    // #endif
    // #ifdef APP
    'Form-type': 'app', // uni.getSystemInfoSync().osName,
    // #endif
  },
  // 回话密钥名称 请勿修改此配置
  TOKEN_NAME: 'Authori-zation',
  // 缓存时间 0 永久
  EXPIRE: 0,
  // 分页最多显示条数
  LIMIT: 10,
  // 客服地址
  SERVICE_CONSUMER_URL: 'https://work.weixin.qq.com/kfid/kfc92d40a8cdf88013a',
  // 是否开启app的支付
  APP_PAY_ENABLE: import.meta.env.VITE_APP_PAY_ENABLE === 'true',
}

export const {
  HTTP_REQUEST_URL,
  OSS_URL,
  HEADER,
  EXPIRE,
  LIMIT,
  TOKEN_NAME,
  SERVICE_CONSUMER_URL,
  H5_SITE_URL,
  APP_PAY_ENABLE,
} = config
export default config
