<script setup lang="ts">
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app'
import { useSettingStore } from './store'
import { useTabBarStore } from '@/store/tabbar'
import JPush from './utils/jpush'
import {getWechatParams} from "@/utils";

const tabBarStore = useTabBarStore()

onLaunch(() => {
  console.log('app.vue on launch')
  // useSettingStore().loadSetting()
  // uni.loadFontFace({
  //   global: true,
  //   family: 'HarmonyOS Sans',
  //   source: 'url("src/static/fonts/HarmonyOS_Sans_Regular.ttf")',
  //   success() {
  //     console.log('success')
  //   },
  // })
	tabBarStore.resetToFirstTab()
  console.log('App Launch end')
 
})
onShow(() => {
  // console.log('App Show')
  const launchData = uni.getLaunchOptionsSync()
  console.log('App onwShow launchData: ', launchData)


  const queryData = uni.getEnterOptionsSync()
  console.log('App queryData: ', queryData)
  // console.log('app.vue queryData.query: ', queryData.query)

  const urlParams = new URLSearchParams(window.location.search)
  console.log('App urlParams', urlParams)
  console.log('App location url', location.href)

  const wechatParams = getWechatParams()
  console.log('App 微信授权参数 ==> ', wechatParams)

  if (wechatParams.code && wechatParams.state) {

    // useRouter().redirectTo(
    //   `/pages/users/auth/wechat-login?code=${wechatParams.code}&state=${wechatParams.state}`,
    // )
  }
















































})
onHide(() => {
  // console.log('App Hide')
})

</script>

<style lang="scss">
// @font-face {
//   font-family: HarmonyOS Sans;
//   src: url('~@/static/fonts/HarmonyOS_Sans_Regular.ttf');
// }
// @font-face {
//   font-family: HarmonyOS SansSC;
//   src: url('~@/static/fonts/HarmonyOS_SansSC_Medium.ttf');
// }
// * {
//   font-family: 'HarmonyOS Sans', 'HarmonyOS SansSC';
// }

* {
  font-family: -apple-system-font,Helvetica Neue, Roboto, 'Noto Sans', 'Open Sans', sans-serif;
}

/* stylelint-disable selector-type-no-unknown */
button::after {
  border: none;
}

swiper,
scroll-view {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

// 单行省略，优先使用 unocss: text-ellipsis
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 两行省略
.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 三行省略
.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
